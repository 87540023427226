/* eslint-disable prettier/prettier */
import MoftButton from '../../../../common/components/MoftButton/MoftButton';
import Frame from './AboutFrame.png';
import TextBorder from './TextBorder.png';
import Collage from './Collage.png';
import './About.scss';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import Rectangle from './MobileRectangle.png';
import { useAuth } from 'react-oidc-context';
import { hasToken } from '../../../../common/services/api/auth';
import Swal from 'sweetalert2';

export default function About() {
  const auth = useAuth();

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash.substring(1));
        if (element) element.scrollIntoView({ behavior: 'smooth' });
      }, 75);
    }
  }, []);

  const nav = useNavigate();
  return (
    <div className="about-container">
      <h1 className="mobileTitle">M.O.F.T. II 2025</h1>
      <img src={Collage} alt="" className="mobileImg" />
      <div className="aboutButton mobile-btn">
        <MoftButton
          text={'REZERVĂ BILETE'}
          onClick={() => {
            if (hasToken()) {
              nav('/tickets');
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Neautorizat',
                text: 'Trebuie să fii logat pentru a rezerva bilete!',
              });
            }
          }}
          type="button"
        />
      </div>
      <div className="mobileDescription"></div>
      <div className="aboutIntroduction">
        <img src={Collage} alt="Collage" className="aboutImg" />
      </div>
      <div className="aboutTextBox">
        <img src={Frame} alt="Frame" className="frameImg" />
        <img src={Rectangle} alt="" className="mobileRectangle" />
        <h1 className="aboutTitle">
          DESPRE <br /> EVENIMENT
        </h1>
        <img src={TextBorder} alt="Border" className="TextBorderImg" />
        <div className="textDescription">
          <p>
            Arta și cultura sunt un refugiu din agitația cotidianului, iar LSAC București te invită
            să le descoperi din nou în cadrul evenimentului cultural M.O.F.T. (Muzee. Operă. Film.
            Teatru.), ediția 27! Această primăvară aduce o nouă ocazie de a te bucura de experiențe
            culturale de neuitat. Evenimentul va avea loc în perioada 27 martie - 27 aprilie și
            aduce activități diverse, inclusiv workshop-uri inedite care îți vor stârni
            curiozitatea, alături de alte surprize. Dacă ești student al Facultății de Automatică și
            Calculatoare și vrei să explorezi cele mai importante instituții culturale, te așteptăm
            să îți creezi un cont în portalul SSO:
            <span
              className="registerLink"
              onClick={() => {
                auth.signinRedirect();
              }}
            >
              înregistrează-te
            </span>
            adăugând datele de student.
          </p>

          <div className="aboutButton">
            <MoftButton
              text={'REZERVĂ BILETE'}
              onClick={() => {
                if (hasToken()) {
                  nav('/tickets');
                } else {
                  // auth.signinRedirect();
                  Swal.fire({
                    icon: 'warning',
                    title: 'Neautorizat',
                    text: 'Trebuie să fii logat pentru a rezerva bilete!',
                  });
                }
              }}
              type="button"
            />
          </div>

          <div className="data">
            <br />
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}
