import axios from 'axios';
import { API_URL } from '../../constants';
import { hasToken } from './auth';
import { IReview } from '../../interfaces/api/review.interface';

const instance = axios.create({
  baseURL: API_URL,
  // headers: {
  //   Authorization: `Bearer ${hasToken()}`,
  // },
});

export async function fetchSuggestions() {
  return await instance
    .get('suggestions/public', {
      headers: {
        Authorization: `Bearer ${hasToken()}`,
      },
    })
    .then(res => res.data);
}

export async function postSuggestion(title: string, author: string, description: string) {
  return await instance.post(
    'suggestions/',
    {
      title,
      author,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${hasToken()}`,
      },
    },
  );
}

export async function fetchSuggestionReviews(id: number) {
  return await instance
    .get(`reviews/suggestion/${id}`)
    .then(res => {
      return res.data;
    })
    .then(reviews => reviews.filter((review: IReview) => review.isSelected))
    .catch(error => {
      console.error('Error fetching suggestion:', error);
      throw error;
    });
}

export async function postReview(message: string, suggestionId: number) {
  return await instance.post(
    'reviews/',
    {
      message,
      suggestionId,
    },
    {
      headers: {
        Authorization: `Bearer ${hasToken()}`,
      },
    },
  );
}

export async function handleLikes(suggestionId: number) {
  return await instance
    .post(
      'suggestions/toggleLike',
      { suggestionId },
      {
        headers: {
          Authorization: `Bearer ${hasToken()}`,
        },
      },
    )
    .catch(error => {
      console.error('Error toggling likes', error);
      throw error;
    });
}

export async function checkIfAlreadyLiked(id: number) {
  return await instance
    .get(`suggestions/${id}/checkLike`, {
      headers: {
        Authorization: `Bearer ${hasToken()}`,
      },
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.error('Error checking for already liked:', error);
      throw error;
    });
}
