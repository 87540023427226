import axios from 'axios';
import { API_URL } from '../../constants';
import { IUserLogin, IUserLoginReceived } from '../../interfaces/api/auth.interface';

const instance = axios.create({
  baseURL: API_URL,
});

export function login(data: IUserLogin) {
  return instance.post<IUserLoginReceived>('/auth/login', data);
}

export function hasToken(): string | null {
  return sessionStorage.getItem('token');
}
