import axios from 'axios';
import { API_URL } from '../../constants';
import { hasToken } from './auth';

const instance = axios.create({
  baseURL: API_URL,
});

export async function fetchNewsItems(category: string) {
  return await instance
    .get(`news?category=${category}`, {
      headers: {
        Authorization: `Bearer ${hasToken()}`,
      },
    })
    .then(res => res.data);
}
