import MoftButton from '../../common/components/MoftButton/MoftButton';
import Page from '../../common/components/Page/Page';
import CarouselComponent from '../../common/components/Carousel/Carousel';
import BookItem from './BookItem';
import { useEffect, useRef, useState } from 'react';
import { ISuggestion } from '../../common/interfaces/api/suggestion.interface';
import { fetchSuggestions, postSuggestion } from '../../common/services/api/books';
import PortraitModal from '../../common/components/PortraitModal/PortraitModal';
import { hasToken } from '../../common/services/api/auth';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import Swal from 'sweetalert2';

function Books() {
  const [modalOpen, setModalOpen] = useState(false);
  const [books, setBooks] = useState<ISuggestion[]>([]);
  const bookItems = books.map(book => <BookItem key={book.id} book={book} />);
  const [itemsPerSlide, setItemsPerSlide] = useState(window.innerWidth > 767 ? 6 : 2);
  const auth = useAuth();

  useEffect(() => {
    if (!hasToken()) {
      auth.signinRedirect();
      return;
    }
    fetchSuggestions().then(book => setBooks(book));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setItemsPerSlide(window.innerWidth > 767 ? 6 : 2);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSuggestionClickis = () => {
    if (hasToken() != undefined) {
      setModalOpen(true);
    } else {
      auth.signinRedirect();
    }
  };

  return (
    <Page>
      <div>
        <div className="m-auto mx-3 mt-5 px-sm-0 px-3 font-style">
          Primăvara este momentul perfect pentru a descoperi povești noi care să te inspire în
          fiecare zi! 📚🎬
          <br />
          Între 27 martie și 27 aprilie, te invităm să împărtășești cărțile, filmele și serialele
          tale preferate cu alți studenți pasionați de povești bine spuse și să te inspiri din
          recomandările lor!
          <br /> Fie că este vorba de un roman care te-a făcut să zâmbești, un film care te-a
          emoționat sau un serial care te-a ținut cu sufletul la gură, locul lui este aici. ✨
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4 mb-3">
        <MoftButton
          text="LASĂ O SUGESTIE"
          type="button"
          onClick={handleSuggestionClickis}
          size="large"
        />
      </div>
      <div className="mb-0 ms-2 me-2 font-style">
        <p>Sugestiile voastre vor fi verificate de către echipă şi adăugate ulterior pe site.</p>
      </div>
      <div className="d-flex justify-content-center mb-5">
        <CarouselComponent itemsPerSlide={itemsPerSlide} items={bookItems} />
      </div>
      <SuggestionModal show={modalOpen} onHide={() => setModalOpen(false)} />
    </Page>
  );
}

interface SuggestionModalProps {
  show: boolean;
  onHide(): void;
}

function SuggestionModal({ show, onHide }: SuggestionModalProps) {
  const titleRef = useRef<HTMLInputElement>(null);
  const authorRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const nav = useNavigate();

  const submitSuggestion = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    postSuggestion(
      titleRef.current!.value,
      authorRef.current!.value,
      descriptionRef.current!.value,
    ).then(() => {
      onHide();
      Swal.fire({
        title:
          'Sugestia a fost trimisă cu succes, urmează să fie evaluată de echipa noastră și apoi aprobată pe site',
        icon: 'success',
        confirmButtonText: 'Am înțeles',
        timer: 10000,
        timerProgressBar: true,
      });
    });
    return false;
  };

  return (
    <PortraitModal show={show} onHide={onHide}>
      <div className="modal-top">
        <h2>LASĂ O SUGESTIE</h2>
        <p>- anonimă -</p>
      </div>
      <form onSubmit={submitSuggestion} className="book-suggestion">
        <input type="text" placeholder="Titlu" ref={titleRef} required />
        <input
          type="text"
          placeholder="Autor/Regizor (daca nu știi, pune ' - ')"
          ref={authorRef}
          required
        />
        <textarea
          className="h-100 description "
          placeholder="Scurta descriere"
          ref={descriptionRef}
          required
        />

        <div className="d-flex justify-content-center mt-3">
          <MoftButton text="TRIMITE" type="submit" size="small" />
        </div>
      </form>
    </PortraitModal>
  );
}

export default Books;
