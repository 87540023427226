import axios from 'axios';
import { API_URL } from '../../constants';
import { hasToken } from './auth';

const instance = axios.create({
  baseURL: API_URL,
});

export async function fetchBlogPosts(page: number, limit: number) {
  return await instance
    .get(`blog/paginated-posts/?page=${page}&limit=${limit}`, {
      headers: {
        Authorization: `Bearer ${hasToken()}`,
      },
    })
    .then(res => res.data);
}

export async function fetchMostLikedPosts() {
  return await instance
    .get('blog/mostLiked', {
      headers: {
        Authorization: `Bearer ${hasToken()}`,
      },
    })
    .then(res => res.data)
    .catch(error => {
      console.error('Error fetching most liked posts:', error);
      throw error;
    });
}

export async function postBlogPost(content: string, imageUrl: File) {
  const formData = new FormData();
  formData.append('content', content);
  formData.append('imageUrl', imageUrl);

  return await instance.post('blog/', formData, {
    headers: {
      Authorization: `Bearer ${hasToken()}`,
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function fetchComments(blogId: number) {
  return await instance
    .get(`blog/${blogId}/comments`, {
      headers: { Authorization: `Bearer ${hasToken()}` },
    })
    .then(res => res.data);
}

export async function postComment(blogPostId: number, content: string) {
  return await instance.post(
    `blog/comments`,
    { content, blogPostId },
    {
      headers: { Authorization: `Bearer ${hasToken()}` },
    },
  );
}

export async function deletePost(blogId: number) {
  return await instance.delete(`blog/${blogId}`, {
    headers: { Authorization: `Bearer ${hasToken()}` },
  });
}

export async function handleLikes(blogPostId: number) {
  return await instance
    .post(
      'blog/toggleLike',
      { blogPostId },
      {
        headers: {
          Authorization: `Bearer ${hasToken()}`,
        },
      },
    )
    .catch(error => {
      console.error('Error toggling likes', error);
      throw error;
    });
}

export async function checkIfAlreadyLiked(id: number) {
  return await instance
    .get(`blog/${id}/checkIfLiked`, {
      headers: {
        Authorization: `Bearer ${hasToken()}`,
      },
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.error('Error checking for already liked:', error);
      throw error;
    });
}

export async function reportPost(blogPostId: number, reason: string) {
  return await instance
    .post(
      'blog/flag',
      { blogPostId, reason },
      {
        headers: {
          Authorization: `Bearer ${hasToken()}`,
        },
      },
    )
    .catch(error => {
      console.error('Error reporting post', error);
      throw error;
    });
}
