import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';
import { hasToken } from '../../services/api/auth';
import './Header.scss';
import { useAuth } from 'react-oidc-context';
import Logo from '../../../pages/Placeholder/pictures/logo.png';

export default function Header(): JSX.Element {
  const auth = useAuth();

  const handleAuthClick = (e: React.MouseEvent) => {
    // In development, let the NavLink handle the navigation
    // We keep the secondary login in development, so we can keep our mock accounts available when no sso is available
    if (process.env.NODE_ENV === 'production') {
      e.preventDefault();
      auth.signinRedirect();
    }
  };

  return (
    <Navbar
      collapseOnSelect
      sticky="top"
      expand="lg"
      style={{ backgroundColor: '#510303' }}
      className="navbar-shadow">
      <Container>
        <Navbar.Brand href="/">
          <img src={Logo} alt="logo" className="navbar-logo"></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="navbar-toggler-white" />
        <Navbar.Collapse id="responsive-navbar-nav" className="right">
          <Nav>
            <Nav.Link data-to-scrollspy-id="about" className="text-white" href="/#about">
              DESPRE
            </Nav.Link>
            <Nav.Link data-to-scrollspy-id="sponsors" className="text-white" href="/#sponsors">
              PARTENERI
            </Nav.Link>
            {/* <Nav.Link data-to-scrollspy-id="podcasts" className="text-white" href="/#podcasts">
              PODCASTURI
            </Nav.Link> */}
            <Nav.Link data-to-scrollspy-id="contact" className="text-white" href="/#contact">
              CONTACT
            </Nav.Link>
            {hasToken() && (
              <NavLink className="nav-link text-white" to="/suggestions">
                SUGESTII
              </NavLink>
            )}
            {/* <NavLink className="nav-link text-white" to="/recommendations">
              RECOMANDĂRI
            </NavLink> */}
            {hasToken() && (
              <NavLink className="nav-link text-white" to="/tickets">
                BILETE
              </NavLink>
            )}
            {hasToken() && (
              <NavLink className="nav-link text-white" to="/calendar">
                CALENDAR
              </NavLink>
            )}
            <Nav.Link data-to-scrollspy-id="team" className="text-white" href="/team">
              ECHIPĂ
            </Nav.Link>
            {hasToken() && (
              <NavLink className="nav-link text-white" to="/blog">
                BLOG
              </NavLink>
            )}
            {!hasToken() ? (
              <>
                <NavLink className="nav-link text-white" to="/login" onClick={handleAuthClick}>
                  AUTENTIFICARE
                </NavLink>
              </>
            ) : (
              <>
                <NavLink className="nav-link text-white" to="/quiz">
                  QUIZ
                </NavLink>
                <NavLink className="nav-link text-white" to="/logout">
                  LOGOUT
                </NavLink>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
