import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import './App.css';
import Login from './pages/Login/Login';
import Logout from './pages/Logout/Logout';
import Tickets from './pages/Tickets/Tickets';
import Recommendations from './pages/Recommendations/Recommendations';
import Page404 from './pages/Page404/Page404';
import Quiz from './pages/Quiz/Quiz';
import Calendar from './pages/Calendar/Calendar';
import Placeholder from './pages/Placeholder/Placeholder';
import Team from './pages/Team/Team';
import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import Books from './pages/Books/Books';
import Swal from 'sweetalert2';
import Blog from './pages/Blog/Blog';

function App() {
  const auth = useAuth();

  useEffect(() => {
    if (auth.isLoading) return;
    if (!auth.isAuthenticated) return;

    //check if user is student
    const user = auth.user!.profile;

    if (!user.student) {
      // show modal
      const response = 'You are not a student';
      Swal.fire({
        title: response,
        icon: 'error',
        timer: 1500,
        showConfirmButton: false,
      });
      sessionStorage.removeItem('token');
      auth.removeUser();
    } else {
      const student_email = user.student_email as string;
      if (
        !user.student_email ||
        !(student_email.endsWith('upb.ro') || student_email.endsWith('pub.ro'))
      ) {
        // show modal
        const response = 'You are not a UPB student';
        Swal.fire({
          title: response,
          icon: 'error',
          timer: 1500,
          showConfirmButton: false,
        });
        sessionStorage.removeItem('token');
        auth.removeUser();
      } else if (!user.student_email_confirmed) {
        // show modal
        const response = `
          Verify your student email.<br>
          If you didn't receive a confirmation email, check out this page: <br>
          <a href="https://sso.lsacbucuresti.ro/Identity/Account/Manage/Student" target="_blank" rel="noopener noreferrer">
            SSO resend confirmation email
          </a><br>
          (Student Status > Resend verification email)
        `;
        Swal.fire({
          html: response,
          icon: 'error',
          timer: 7000,
          showConfirmButton: false,
        });
        sessionStorage.removeItem('token');
        auth.removeUser();
      }
    }
  }, [auth.isLoading]);
  return (
    <div className="App">
      {process.env.REACT_APP_COMING_SOON === 'true' ? (
        <Placeholder />
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* You can only login in development with the mock accounts generated by the seeder */}
            {process.env.NODE_ENV === 'development' && <Route path="/login" element={<Login />} />}
            <Route path="/logout" element={<Logout />} />
            <Route path="/tickets" element={<Tickets />} />
            {/* <Route path="/recommendations" element={<Recommendations />} /> */}
            <Route path="/quiz" element={<Quiz />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/*" element={<Page404 />} />
            <Route path="/team" element={<Team />} />
            <Route path="/suggestions" element={<Books />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/404" element={<Page404 />} />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
