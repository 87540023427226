import ScrollSpy from 'react-ui-scrollspy';
import Page from '../../common/components/Page/Page';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
// import PodcastList from './components/PodcastList/PodcastList';
import PartnerList from './components/Partners/PartnerList';
import SponsorList from './components/Partners/SponsorList';

export default function Home() {
  return (
    <Page>
      {/* <div className="container"> */}
      <ScrollSpy offsetBottom={100} offsetTop={100} scrollThrottle={100}>
        <div id="about">
          <About />
        </div>
        <br />
        <div
          id="sponsors"
          className="mt-2"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '7vw',
          }}>
          <PartnerList title="PARTENERI" />
        </div>
        {/* <div className="mt-5">
          <SponsorList title="Sponsori" />
        </div> */}
        {/* <div id="podcasts" className="mt-5">
          <PodcastList />
        </div> */}
        <div id="contact" className="mt-2">
          <Contact />
        </div>
      </ScrollSpy>
    </Page>
  );
}
