export enum CategoryEnum {
  RECOMMENDATION = 'RECOMMENDATION',
  PODCAST = 'PODCAST',
  TICKET_UPDATE = 'TICKET_UPDATE',
  EVENT_UPDATE = 'EVENT_UPDATE',
}

export interface INewsItem {
  id: number;
  title: string;
  content: string;
  imageUrl: string;
  type: CategoryEnum;
}
