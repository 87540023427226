import styles from './MoftButton.module.scss';
import lsacLogo from './LogoLSAC.png';

interface IButtonProps {
  text: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  type: 'button' | 'submit' | 'reset' | undefined;
  lsacButton?: boolean;
  size?: 'small' | 'large';
}

export default function MoftButton({
  text,
  onClick,
  type,
  lsacButton,
  size = 'large',
}: IButtonProps) {
  return (
    <button
      onClick={onClick}
      className={`${styles.btn} ${styles[size]}`}
      type={type ? type : 'button'}>
      {lsacButton && <img src={lsacLogo} alt="logo" className={styles.logo} />}
      <p className={`m-0 ${styles.text}`}>{text}</p>
    </button>
  );
}
