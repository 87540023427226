import './News.scss';
import { CategoryEnum, INewsItem } from '../../../common/interfaces/api/news.interface';
import ticketsLeft from '../images/tickets_left.png';
import dontForget from '../images/dont_forget_to_post.png';
import podcastIcon from '../images/podcast_icon.png';
import moftLogo from '../images/moft_logo.png';

function NewsItem({ news }: { news: INewsItem }) {
  return (
    <div className="news-item">
      <div className="news-container">
        <img
          src={
            news.type === CategoryEnum.PODCAST
              ? podcastIcon
              : news.type === CategoryEnum.RECOMMENDATION
                ? moftLogo
                : news.type === CategoryEnum.TICKET_UPDATE
                  ? ticketsLeft
                  : dontForget
          }
          alt={news.title}
          className="news-image"
          width="100"
          height="100"
        />
        <div className="news-info">
          <div className="news-title">{news.title}</div>
          <div
            className="news-text"
            dangerouslySetInnerHTML={{
              __html: news.content.replace(/\\n/g, '<br/>'),
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default NewsItem;
