import { ContactForm } from './ContactForm';
import ContactIMG from './ContactCollage.png';
import './contact.scss';

export default function Contact() {
  return (
    <>
      <div className="contact-container d-flex align-items-center justify-content-center ">
        <img src={ContactIMG} alt="ContactCollage" className="contactImg" />
        <div className="my-5 text-center">
          <ContactForm />
        </div>
      </div>
    </>
  );
}
