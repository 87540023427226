import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import ErrorMessage from '../../../../common/components/ErrorMessage/ErrorMessage';
import { ISponsor } from '../../../../common/interfaces/api/sponsors.interface';
import { getSponsors } from '../../../../common/services/api/sponsors';
import PartnerItem from './PartnerItem';
import CarouselComponent from '../../../../common/components/Carousel/Carousel';

import titiaur from '../../../../common/assets/titiaur.png';
import titiaurlogo from '../../../../common/assets/titiaurlogo.png';
import kauflandLogo from '../../../../common/assets/kaufland-logo.png';

interface PartnerListProps {
  title: string;
}
export default function PartnerList(props: PartnerListProps) {
  const errorMessage = `„Ai puțintică răbdare!”<br/>&nbsp &nbsp &nbsp &nbsp &nbsp &nbsp—Trahanache`;

  const [sponsors, setSponsors] = useState<ISponsor[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    getSponsors('institution')
      .then(response => {
        setSponsors(response.data);

        setIsError(false);
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  }, []);

  const sponsorItems = sponsors.map(sponsor => (
    <PartnerItem key={sponsor.id} sponsor={sponsor} type="carousel" />
  ));

  return (
    <>
      <div>
        <h1 className="mt-1">{props.title}</h1>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '50px',
          position: 'relative',
        }}>
        <img src={titiaur} alt="Background Image" style={{ maxWidth: '80%', height: 'auto' }} />
        <img
          src={titiaurlogo}
          alt="Overlay Logo"
          style={{
            maxWidth: '15%',
            marginTop: '35px',
            height: 'auto',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
        <style>
          {`
        @media (max-width: 768px) {
          img[alt="Overlay Logo"] {
            margin-top: 20px !important;
          }
        }
          `}
        </style>
      </div>
      <div>
        <img
          src={kauflandLogo}
          alt="Kaufland Logo"
          style={{ maxWidth: '25%', height: 'auto', marginBottom: '30px' }}
        />
      </div>
      {!isLoading ? (
        !isError && sponsors.length !== 0 ? (
          <CarouselComponent items={sponsorItems} itemsPerSlide={6} />
        ) : (
          <ErrorMessage message={errorMessage} customClass="background-black" />
        )
      ) : (
        <Spinner style={{ margin: 'auto', marginTop: '' }} animation="border" />
      )}
    </>
  );
}
