import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PlaceholderImage from '../../common/assets/podcast-placeholder.png';
import { ISuggestion } from './../../common/interfaces/api/suggestion.interface';
import PortraitModal from '../../common/components/PortraitModal/PortraitModal';
import MoftButton from '../../common/components/MoftButton/MoftButton';
import emptyHeart from './emptyHeart.svg';
import fullHeart from './fullHeart.svg';
import './Books.scss';
import {
  postReview,
  fetchSuggestionReviews,
  handleLikes,
  checkIfAlreadyLiked,
} from '../../common/services/api/books';
import { IReview } from '../../common/interfaces/api/review.interface';
import { hasToken } from '../../common/services/api/auth';
import { useAuth } from 'react-oidc-context';

function BookItem({ book }: { book: ISuggestion }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [likes, setLikes] = useState(book._count.likes);
  const [liked, setLiked] = useState(false);
  const auth = useAuth();
  const openModal = () => {
    setModalOpen(true);
  };

  const handleLike = async () => {
    if (hasToken()) {
      try {
        if (liked) {
          setLikes(prev => prev - 1);
          setLiked(prev => !prev);
          await handleLikes(book.id);
        } else {
          setLikes(prev => prev + 1);
          setLiked(prev => !prev);
          await handleLikes(book.id);
        }
      } catch (error) {
        console.error('Error liking the book:', error);
        setLikes(liked ? likes + 1 : likes - 1);
        setLiked(!liked);
      }
    } else {
      auth.signinRedirect();
    }
  };

  useEffect(() => {
    if (hasToken()) {
      checkIfAlreadyLiked(book.id).then(data => {
        setLiked(data.liked);
      });
    }
  }, [book.id]);

  return (
    <>
      <div className="books-container col-sm-12 col-md-4 mb-3 ">
        {book.isSelected ? (
          <>
            <div className="d-flex flex-row justify-content-center">
              <div
                className="d-flex flex-column justify-content-center book-image mb-2 w-100"
                onClick={openModal}>
                <img src={book.imageUrl || PlaceholderImage} alt="book" />
              </div>
            </div>

            <div className="book-hearts" onClick={handleLike}>
              <img src={liked ? fullHeart : emptyHeart} className="heart" alt="heart" />
              <span>{likes}</span>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex flex-row justify-content-center">
              <div className="d-flex flex-column justify-content-center book-image pending mb-2 w-100 position-relative">
                {/* Banda pe diagonală */}
                <div className="ribbon">In Review</div>

                {/* Imaginea */}
                <img src={book.imageUrl || PlaceholderImage} alt="book" className="book-cover" />
              </div>
            </div>

            <div className="book-hearts" style={{ height: '30px' }}></div>
          </>
        )}
      </div>
      <ReviewsModal show={modalOpen} onHide={() => setModalOpen(false)} book={book} />
    </>
  );
}

interface ReviewsModalProps {
  show: boolean;
  onHide(): void;
  book: ISuggestion;
}

// reviews modal that is displayed when a book is clicked
function ReviewsModal({ show, onHide, book }: ReviewsModalProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [reviews, setReviews] = useState<IReview[]>([]);

  useEffect(() => {
    if (show) {
      fetchSuggestionReviews(book.id).then((reviews: IReview[]) => {
        setReviews(reviews);
      });
    }
  }, [show, book.id]);

  const handleNewReview = (newReview: IReview) => {
    setReviews(prevReviews => [...prevReviews, newReview]);
  };

  // the list of mapped reviews + minimal design for a box containing a review: user + mesage
  const reviewsItems = reviews.map(review => (
    <div key={review.id} className="d-flex flex-column mb-3 p-3 border rounded shadow-sm">
      <div className="d-flex align-items-center mb-2">
        <p className="user-name mb-0 fw-bold">{review.user.name}</p>
      </div>
      <p className="review-message mb-0 text-muted">{review.message}</p>
    </div>
  ));

  return (
    <PortraitModal show={show} onHide={onHide}>
      <div className="modal-top">
        <h2>{book.title}</h2>
        <h2>{book.author}</h2>
      </div>
      <div className="book-reviews">
        <h5 className="header-reviews">Review-uri carte: </h5>
        <div className="reviewList">{reviewsItems}</div>
        <div className="d-flex justify-content-center">
          {hasToken() && (
            <MoftButton
              text="Lasă un review"
              type="button"
              onClick={() => setModalOpen(true)}
              size="small"
            />
          )}
        </div>
      </div>
      <WriteAReviewModal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        book={book}
        onReviewSubmit={handleNewReview}
      />
    </PortraitModal>
  );
}

interface WriteAReviewModalProps {
  show: boolean;
  onHide(): void;
  book: ISuggestion;
  onReviewSubmit: (review: IReview) => void;
}

// modal for writing a review
function WriteAReviewModal({ show, onHide, book, onReviewSubmit }: WriteAReviewModalProps) {
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const submitReview = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (descriptionRef.current) {
      try {
        const response = await postReview(descriptionRef.current.value, book.id);
        const newReview: IReview = response.data; // Extragem `data`, care conține obiectul real

        onReviewSubmit(newReview); // Adăugăm review-ul în listă

        onHide();
      } catch (error) {
        console.error('Error submitting review:', error);
      }
    }
  };

  return (
    <PortraitModal show={show} onHide={onHide}>
      <div className="modal-top">
        <h2>{book.title}</h2>
        <h2>{book.author}</h2>
      </div>

      <form onSubmit={submitReview} className="book-reviews">
        <textarea
          placeholder="Descriere"
          ref={descriptionRef}
          required
          className="reviewList h-100"
        />
        <div></div>
        <div className="d-flex justify-content-center ">
          <MoftButton text="TRIMITE" type="submit" size="small" />
        </div>
      </form>
    </PortraitModal>
  );
}

export default BookItem;
