import Page from '../../common/components/Page/Page';
import '../Blog/Blog.scss';
import NewsItem from '../Blog/components/NewsItem';
import PostItem from '../Blog/components/PostItem';
import { IPostItem } from '../../common/interfaces/api/postitem.interface';
import { INewsItem } from '../../common/interfaces/api/news.interface';
import { fetchBlogPosts, postBlogPost, fetchMostLikedPosts } from '../../common/services/api/blog';
import { fetchNewsItems } from '../../common/services/api/news';
import { useState, useEffect, useRef } from 'react';
import { useAuth } from 'react-oidc-context';
import Swal from 'sweetalert2';
import switch_logo from '../Blog/images/switch_icon.png';
import startPostImage from '../Blog/images/start_a_post_image.png';
import blogIcon from '../Blog/images/blog_icon.png';
import newsIcon from '../Blog/images/news_icon.png';
import selectImage from '../Blog/images/select_image.png';
import dontForgetImage from '../Blog/images/dont_forget_to_post.png';
import { hasToken } from '../../common/services/api/auth';

function Blog() {
  const [showNews, setShowNews] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [modalOpen, setModalOpen] = useState(false);
  const [posts, setPosts] = useState<IPostItem[]>([]);
  const [category, setCategory] = useState('ALL');
  const [blogFilter, setBlogFilter] = useState('Most Recent');
  const [news, setNews] = useState<INewsItem[]>([]);
  const [page, setPage] = useState(1);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const fetchedPages = useRef(new Set());
  const isFetching = useRef(false);

  const postSectionRef = useRef<HTMLDivElement | null>(null);

  const newsItems = news.map(news => <NewsItem key={news.id} news={news} />);

  const limit = 3;

  const fetchPosts = async () => {
    if (blogFilter === 'Most Recent') {
      if (!hasMorePosts || fetchedPages.current.has(page) || isFetching.current) return;

      try {
        isFetching.current = true;
        fetchedPages.current.add(page);

        const data = await fetchBlogPosts(page, limit);
        const newPosts = data.posts;

        if (!Array.isArray(newPosts)) {
          throw new Error('Invalid response: Expected an array.');
        }

        setPosts(prevPosts => [...prevPosts, ...newPosts]);

        if (!data.hasMore || newPosts.length < limit) {
          setHasMorePosts(false);
        }
      } catch (error) {
        console.error('Failed to fetch blog posts:', error);
      } finally {
        isFetching.current = false;
      }
    } else {
      // Fetch direct fără paginare pentru "Most Liked"
      try {
        const posts = await fetchMostLikedPosts();
        setPosts(posts);
      } catch (error) {
        setPosts([]);
        console.error('Failed to fetch blog posts:', error);
      }
    }
  };

  useEffect(() => {
    setPage(1);
    setHasMorePosts(true);
    if (!hasToken()) {
      auth.signinRedirect();
      return;
    }

    setPosts([]); // Resetăm lista de postări când se schimbă filtrul
    // Resetăm pagina la 1 pentru un nou fetch
    fetchedPages.current.clear(); // Golim paginile încărcate
    fetchPosts();
  }, [blogFilter]);

  useEffect(() => {
    if (blogFilter === 'Most Recent' && !fetchedPages.current.has(page)) {
      fetchPosts();
    }
  }, [page]);

  useEffect(() => {
    if (blogFilter !== 'Most Recent') return;

    const handleScroll = () => {
      let scrollTop, scrollHeight, clientHeight;

      if (isMobile) {
        scrollTop = window.scrollY;
        clientHeight = window.innerHeight;
        scrollHeight = document.body.scrollHeight;
      } else {
        if (!postSectionRef.current) return;
        scrollTop = postSectionRef.current.scrollTop;
        clientHeight = postSectionRef.current.clientHeight;
        scrollHeight = postSectionRef.current.scrollHeight;
      }

      if (scrollTop + clientHeight >= scrollHeight - 100 && hasMorePosts) {
        setPage(prevPage => {
          const nextPage = prevPage + 1;
          if (!fetchedPages.current.has(nextPage) && hasMorePosts && !isFetching.current) {
            return nextPage;
          }
          return prevPage;
        });
      }
    };

    if (isMobile) {
      window.addEventListener('scroll', handleScroll);
    } else if (postSectionRef.current) {
      postSectionRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (isMobile) {
        window.removeEventListener('scroll', handleScroll);
      } else if (postSectionRef.current) {
        postSectionRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasMorePosts, isMobile, blogFilter]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const news = await fetchNewsItems(category);
        setNews(news);
      } catch (error) {
        console.error('Failed to fetch news:', error);
      }
    };

    fetchNews();
  }, [category]);

  const openModal = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Page>
      <>
        <div className="blog-background">
          {/* Buttons for Mobile (Hidden on Desktop) */}
          {isMobile && (
            <div className="mobile-button-container">
              <div className="mobile-blog-button-container">
                <img src={blogIcon} alt="Blog button icon" width="60" height="55" />
                <button onClick={() => setShowNews(false)} className="mobile-blog-button">
                  BLOG
                </button>
              </div>
              <div className="mobile-news-button-container">
                <img src={newsIcon} alt="News button icon" width="60" height="55" />
                <button onClick={() => setShowNews(true)} className="mobile-news-button">
                  NEWS
                </button>
              </div>
            </div>
          )}

          <div className="desktop-divs">
            {/* BLOG SECTION (Visible always on Desktop, Toggles on Mobile) */}
            {!isMobile || !showNews ? (
              <div className="start-post-section">
                <div className="start-post-container">
                  <img
                    className="start-post-image"
                    src={startPostImage}
                    alt="Starting Post Image"
                    width="200"
                    height="100"
                  />
                  <div className="start-post-info">
                    <button className="start-post-main-button" onClick={openModal}>
                      Start a post...
                    </button>
                    {/* <div className="start-post-buttons">
                      <button className="start-post-camera-button"></button>
                      <button className="start-post-gallery-button"></button>
                    </div> */}
                  </div>
                </div>
                {/* Prize Container (Does not exist on Mobile on BLOG) */}
                {!isMobile && (
                  <>
                    <div className="prize-container">
                      <div className="prize-text">
                        POSTAREA CU CELE MAI MULTE APRECIERI VA PRIMI UN PREMIU SURPRIZĂ!
                      </div>
                    </div>

                    <div className="cropped-warning-background">
                      <div className="cropped-warning-container">
                        <img
                          src={dontForgetImage}
                          alt="Cropped Image Warning Container"
                          className="cropped-warning-container-image"
                          width="100"
                          height="100"
                        />
                        <div className="cropped-warning-info">
                          <div className="cropped-warning-title">IMPORTANT</div>
                          <div className="cropped-warning-text">
                            Când vrei să postezi, poza va părea tăiată, dar ea se va posta în
                            întregime
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : null}

            {/* POSTS SECTION (Visible always on Desktop, Toggles on Mobile) */}
            {!isMobile || !showNews ? (
              <div className="view-post-section" ref={postSectionRef}>
                <div className="sort-post-container">
                  {['Most Recent', 'Most Liked'].map(filter => (
                    <button
                      key={filter}
                      onClick={() => setBlogFilter(filter)}
                      className={`blog-filter-container ${blogFilter === filter ? 'active' : ''}`}>
                      {filter === 'Most Recent' ? 'Most recent' : 'Most liked'}
                    </button>
                  ))}
                </div>
                {posts.map(post => (
                  <PostItem key={post.id} post={post} currentUser={auth.user} />
                ))}
                {blogFilter === 'Most Recent' && hasMorePosts && <p>Loading more posts...</p>}
              </div>
            ) : null}

            {!isMobile || showNews ? (
              <div className="news-section">
                {isMobile && <div className="news-mobile-title">ANUNȚURI</div>}
                <div className="news-select-container">
                  {['ALL', 'RECOMMENDATION', 'TICKET_UPDATE', 'PODCAST', 'EVENT_UPDATE'].map(
                    cat => (
                      <button
                        key={cat}
                        onClick={() => setCategory(cat)}
                        className={`news-category-button ${category === cat ? 'active' : ''}`}>
                        {cat === 'ALL'
                          ? 'Toate'
                          : cat === 'RECOMMENDATION'
                            ? 'Recomandări'
                            : cat === 'TICKET_UPDATE'
                              ? 'Bilete'
                              : cat === 'PODCAST'
                                ? 'Podcast'
                                : 'Eveniment'}
                      </button>
                    ),
                  )}
                </div>
                {/* Prize Container (Only Exists on Mobile on NEWS) */}
                {isMobile && (
                  <div className="prize-container">
                    <div className="prize-text">
                      POSTAREA CU CELE MAI MULTE APRECIERI VA PRIMI UN PREMIU SURPRIZĂ!
                    </div>
                  </div>
                )}
                {newsItems}
              </div>
            ) : null}
          </div>
        </div>
        <MakePostModal show={modalOpen} onHide={() => setModalOpen(false)} />
      </>
    </Page>
  );
}

interface MakePostModalProps {
  show: boolean;
  onHide(): void;
}

function MakePostModal({ show, onHide }: MakePostModalProps) {
  const [selectedImage, setSelectedImage] = useState(selectImage);
  const [imageSelected, setImageSelected] = useState(false);
  const [caption, setCaption] = useState('');
  const imageFileRef = useRef<File | null>(null);
  const [isPosting, setIsPosting] = useState(false);

  const compressImage = (
    file: File,
    quality = 0.7,
    maxWidth = 1080,
    maxHeight = 1080,
  ): Promise<File> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = event => {
        const img = new Image();
        img.src = event.target?.result as string;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          if (!ctx) {
            reject(new Error('Could not create canvas context'));
            return;
          }

          let { width, height } = img;

          // redimensionare
          if (width > maxWidth || height > maxHeight) {
            const aspectRatio = width / height;
            if (width > height) {
              width = maxWidth;
              height = Math.round(maxWidth / aspectRatio);
            } else {
              height = maxHeight;
              width = Math.round(maxHeight * aspectRatio);
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          // salvare ca jpeg
          canvas.toBlob(
            blob => {
              if (!blob) {
                reject(new Error('Compression failed'));
                return;
              }

              const compressedFile = new File([blob], 'compressed-image.jpg', {
                type: 'image/jpeg',
              });
              resolve(compressedFile);
            },
            'image/jpeg',
            quality,
          );
        };
      };

      reader.onerror = error => reject(error);
    });
  };

  const handleGalleryUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      // aici aplicam copresia
      const compressedFile = await compressImage(file);
      const imageUrl = URL.createObjectURL(compressedFile);

      setSelectedImage(imageUrl);
      setImageSelected(true);
      imageFileRef.current = compressedFile;
    } catch (error) {
      console.error('Image compression failed:', error);
    }
  };

  const handleImageClick = () => {
    if (!imageSelected) {
      document.getElementById('galleryUpload')?.click();
    }
  };

  const handleClose = () => {
    setSelectedImage(selectImage);
    setImageSelected(false);
    imageFileRef.current = null;
    setCaption('');
    onHide();
  };

  const handlePost = async () => {
    if (!imageFileRef.current) {
      Swal.fire({
        icon: 'error',
        title: 'Eroare!',
        text: 'Te rugăm să selectezi o imagine pentru a posta.',
      });
      return;
    }
    setIsPosting(true);

    try {
      await postBlogPost(caption, imageFileRef.current);
      Swal.fire({
        icon: 'success',
        title: 'Postare reușită!',
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        window.location.reload();
      });
      handleClose();
    } catch (error) {
      console.error('Eroare la postare:', error);
      if (
        typeof error === 'object' &&
        error !== null &&
        'response' in error &&
        (error as any).response?.status === 409
      ) {
        Swal.fire({
          icon: 'info',
          title: 'Postare existentă!',
          text: 'Această postare a fost deja publicată.',
        });
      } else {
        alert('A apărut o eroare!');
      }
    } finally {
      setIsPosting(false);
    }
  };

  if (!show) return null;

  return (
    <div className="make-post-dark-background">
      <div className="make-post-container">
        <div className="make-post-top-button-container">
          <div className="make-post-top-left-button-container">
            <button className="post-button" onClick={handlePost} disabled={isPosting}>
              {isPosting ? 'Posting...' : 'Post'}
            </button>
            {/* Camera Button - Opens Camera */}
            <input
              type="file"
              accept="image/*"
              capture="user"
              style={{ display: 'none' }}
              id="cameraUpload"
              onChange={handleGalleryUpload}
            />
            <button
              className="start-post-camera-button"
              style={{ display: window.innerWidth > 1200 ? 'none' : 'block' }}
              onClick={() => {
                document.getElementById('cameraUpload')?.click();
              }}></button>

            {/* Gallery Button - Opens File Picker */}
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              id="galleryUpload"
              onChange={handleGalleryUpload}
            />
            <button
              className="start-post-gallery-button"
              onClick={() => document.getElementById('galleryUpload')?.click()}></button>
          </div>
          <button className="make-post-exit-button" onClick={handleClose}>
            X
          </button>
        </div>
        <div className="make-post-main-container">
          <div className="make-post-image-container">
            <img
              src={selectedImage}
              alt="Insert image for blog post"
              className="make-post-image"
              onClick={handleImageClick}
              style={{ cursor: selectedImage === selectImage ? 'pointer' : 'default' }}
            />
          </div>
        </div>
        <div className="make-post-bottom-container">
          <input
            className="caption-input"
            type="text"
            placeholder="Write a caption..."
            value={caption}
            maxLength={60}
            onChange={e => setCaption(e.target.value)}
          />
          <svg height="10" width="100%" className="line">
            <line x1="0" y1="5" x2="100%" y2="5" style={{ stroke: 'black', strokeWidth: 1 }} />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default Blog;
