import React, { useEffect, useState } from 'react';
import { IEvent } from '../../../common/interfaces/api/tickets.interface';
import MoftButton from '../../../common/components/MoftButton/MoftButton';
import styles from './EventModal.module.scss';
import xLogo from '../../../common/assets/close.png';
import { useKeyPress } from 'react-use';

interface EventModalProps {
  event: IEvent;
  logo: string;
  onHide: () => void;
  button?: boolean;
  buttonText?: string;
  onClick?: () => void;
  show: boolean;
}

const EventModal: React.FC<EventModalProps> = ({
  event,
  logo,
  button = true,
  buttonText,
  show,
  onHide,
  onClick,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [flippedOnce, setFlippedOnce] = useState(false);

  // useEffect(() => {
  //   if (show) {
  //     setTimeout(() => {
  //       setIsFlipped(true); // Se rotește pe spate
  //       setTimeout(() => {
  //         setIsFlipped(false); // Revine pe față
  //         setFlippedOnce(true);
  //       }, 1200); // Stă pe spate 1 secundă
  //     }, 300); // Stă pe față inițial 1 secundă în plus
  //   }
  // }, [show]);

  useKeyPress((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onHide();
    }
    return true;
  });

  return show ? (
    <div className={styles.modalOverlay} onClick={onHide}>
      <div className={styles.flipCard} onClick={e => e.stopPropagation()}>
        <div
          className={`${styles.flipCardInner} ${isFlipped ? styles.flipped : ''}`}
          onClick={() => setIsFlipped(!isFlipped)}>
          {/* Față - Detalii bilet */}
          <div className={styles.flipCardFront}>
            <button className={styles.closeButton} onClick={onHide}>
              <img src={xLogo} alt="Close" />
            </button>

            <div className="d-flex flex-column">
              <div className={`text-center ${styles.titleTicketFront}`}>{event.name}</div>
              <svg height="10" width="100%" className="line" style={{ marginTop: 0 }}>
                <line x1="0" y1="5" x2="100%" y2="5" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div>

            <div>
              <img className={`${styles.img}`} src={logo} alt="Event" />
            </div>

            <div className={`${styles.textContainer}`}>
              <p className={`fw-bolder ${styles.textCategory}`}>Descriere: </p>
              <p dangerouslySetInnerHTML={{ __html: event.description }} />

              {/* {event.link && (
                <>
                  <div className="d-flex justify-content-center">
                    <MoftButton
                      type="button"
                      text={'Detalii'}
                      onClick={() => window.open(event.link)}
                    />
                  </div>
                </>
              )} */}
            </div>

            <div className="d-flex flex-column align-items-center">
              <div className={`${styles.textContainer} mb-3`}>
                {event.ticketType && (
                  <>
                    <p className={`fw-bolder ${styles.textCategory} mt-2`}>Tip bilet: </p>
                    <p dangerouslySetInnerHTML={{ __html: event.ticketType }} />
                  </>
                )}
              </div>
              <div className="mx-auto mb-3 p-0">
                {button && buttonText ? (
                  <MoftButton
                    text={buttonText}
                    onClick={e => {
                      e.stopPropagation();
                      onClick && onClick();
                    }}
                    type="button"
                    size="small"
                  />
                ) : null}
              </div>

              <div className="text-center">
                <small className={styles.smallText}>
                  Pentru a vedea regulamentul, apasă oriunde pe card.
                </small>
              </div>
            </div>
          </div>

          {/* Spate - Regulament */}
          <div className={styles.flipCardBack}>
            <div className="d-flex flex-column">
              <div className={`text-center ${styles.titleTicketBack}`}>REGULAMENT</div>
              <svg height="10" width="100%" className="line" style={{ marginTop: 0 }}>
                <line x1="0" y1="5" x2="100%" y2="5" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div>
            <div className={styles.rulesContainer}>
              <div className={styles.innerRules}>
                <p>Anunță cu minim 24 h înainte dacă nu mai poți ajunge.</p>
                <p>Poți participa doar dacă ai achiziționat bilet.</p>
                <p>Biletul nu mai poate fi anulat în ultimele 24 h.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default EventModal;
