import './Post.scss';
import { IPostItem } from '../../../common/interfaces/api/postitem.interface';
import { useState } from 'react';
import { useEffect, useRef } from 'react';
import { fetchComments, postComment } from '../../../common/services/api/blog';
import { IComment } from '../../../common/interfaces/api/comment.interface';
import Swal from 'sweetalert2';
import { deletePost } from '../../../common/services/api/blog';
import { hasToken } from '../../../common/services/api/auth';
import { checkIfAlreadyLiked, handleLikes, reportPost } from '../../../common/services/api/blog';

function PostItem({ post, currentUser }: { post: IPostItem; currentUser: any }) {
  const [showComments, setShowComments] = useState(false);
  const toggleComments = () => {
    setShowComments(prev => !prev);
  };
  const [likes, setLikes] = useState(post._count.likes);
  const [liked, setLiked] = useState(false);
  const [newComment, setNewComment] = useState('');
  const fetchedLikes = useRef(new Set());

  const handleLike = async () => {
    try {
      if (liked) {
        setLikes(prev => prev - 1);
        setLiked(prev => !prev);
        await handleLikes(post.id);
      } else {
        setLikes(prev => prev + 1);
        setLiked(prev => !prev);
        await handleLikes(post.id);
      }
    } catch (error) {
      console.error('Error liking the book:', error);
      setLikes(liked ? likes + 1 : likes - 1);
      setLiked(!liked);
    }
  };

  const handleReport = async () => {
    const { value: reason } = await Swal.fire({
      title: 'Ești sigur că vrei să raportezi această postare?',
      input: 'textarea',
      inputPlaceholder: 'Scrie motivul raportării aici...',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Da, raportez!',
      cancelButtonText: 'Nu, anulează',
    });

    if (reason) {
      try {
        await reportPost(post.id, reason);
        Swal.fire('Raportată!', 'Postarea a fost raportată.', 'success');
      } catch (error: any) {
        const errorMessage =
          error.response?.data?.message || 'Postarea a fost deja scoasă din Blog.';
        Swal.fire('Eroare!', errorMessage, 'error').then(() => {
          window.location.reload();
        });
      }
    }
  };

  const handleDelete = async () => {
    Swal.fire({
      title: 'Ești sigur că vrei să ștergi această postare?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Da, șterge!',
      cancelButtonText: 'Nu, anulează',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deletePost(post.id);

          console.log('Postare ștearsă:', post.id);

          Swal.fire('Șters!', 'Postarea a fost ștearsă.', 'success').then(() => {
            window.location.reload();
          });
        } catch (error) {
          console.error('Eroare la ștergere:', error);
          Swal.fire('Eroare!', 'A apărut o eroare la ștergerea postării.', 'error');
        }
      }
    });
  };

  const handleSubmit = async () => {
    if (!newComment.trim()) return; // Evităm trimiterea unui comentariu gol

    try {
      await postComment(post.id, newComment);
      setNewComment('');
      Swal.fire('Comentariu adăugat!', 'Comentariul a fost adăugat cu succes.', 'success').then(
        () => {
          window.location.reload();
        },
      );
    } catch (error) {
      console.error('Eroare la postare comentariu:', error);
    }
  };

  //For blog post comment section modal
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    if (hasToken() && !fetchedLikes.current.has(post.id)) {
      fetchedLikes.current.add(post.id);

      const checkLikeStatus = async () => {
        try {
          const res = await checkIfAlreadyLiked(post.id);
          setLiked(res);
        } catch (error) {
          console.error('Error checking if already liked:', error);
        }
      };

      checkLikeStatus();
    }
  }, [post.id]);

  return (
    <>
      <div className="post-item">
        <div className="author-info">
          <div className="author-last-name">{post.user.name}</div>
        </div>
        <div className="post-container">
          <img
            src={post.imageUrl}
            alt={post.title}
            className="post-image"
            width="100"
            height="100"
          />
          <div className="post-info">
            <div className="post-buttons">
              <div className="left-groups">
                <div className="like-group">
                  <button
                    className={`like-button ${liked ? 'liked' : ''}`}
                    onClick={handleLike}></button>
                  <div className="like-counter">{likes}</div>
                </div>
                <div className="comment-group">
                  <button className="comment-button" onClick={toggleComments}></button>
                  <div className="comment-counter">{post.selectedCommentsCount}</div>
                </div>
              </div>
              {currentUser?.profile.sub === post.user.id ? (
                <button className="trash-button" onClick={handleDelete}></button>
              ) : (
                <div className="report-container">
                  <button className="report-button" onClick={handleReport}>
                    Report
                  </button>
                </div>
              )}
            </div>
            <div className="post-title-container">
              <span className="post-title-header">Caption:</span>
              <span className="post-title"> {post.content}</span>
            </div>
            <button className="see-all-comments-message" onClick={openModal}>
              See all comments
            </button>

            {/* Add a comment section is only visible when pressing the comment icon*/}
            {showComments && (
              <div className="add-a-comment-section">
                <div className="add-a-comment-group">
                  <input
                    className="comment-input"
                    type="text"
                    placeholder="Add a comment..."
                    value={newComment}
                    maxLength={100}
                    onChange={e => setNewComment(e.target.value)}
                    autoFocus
                  />
                  <button className="send-button" onClick={handleSubmit}></button>
                </div>
                <svg height="10" width="100%" className="line">
                  <line
                    x1="0"
                    y1="5"
                    x2="100%"
                    y2="5"
                    style={{ stroke: 'black', strokeWidth: 1 }}
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
      </div>
      <CommentSectionModal show={modalOpen} onHide={() => setModalOpen(false)} blogPost={post} />
    </>
  );
}

interface CommentSectionModalProps {
  show: boolean;
  onHide(): void;
  blogPost: IPostItem;
}

//comment section for blog post when Show All Comments is clicked
function CommentSectionModal({ show, onHide, blogPost }: CommentSectionModalProps) {
  const [comments, setComments] = useState<IComment[]>([]);
  const [newComment, setNewComment] = useState('');

  useEffect(() => {
    if (show) {
      fetchComments(blogPost.id)
        .then(data => setComments(data))
        .catch(error => console.error('Eroare la fetch comments:', error));
    }
  }, [show, blogPost.id]);

  const handleSubmit = async () => {
    if (!newComment.trim()) return; // Evităm trimiterea unui comentariu gol

    try {
      const response = await postComment(blogPost.id, newComment);
      const addedComment = response.data;

      setComments(prevComments => [...prevComments, addedComment]);
      setNewComment('');
    } catch (error) {
      console.error('Eroare la postare comentariu:', error);
    }
  };

  if (!show) return null;

  return (
    <div className="comment-section-dark-background">
      <div className="comment-section-container">
        <div className="comment-top-button-container">
          <button className="comment-exit-button" onClick={onHide}>
            X
          </button>
        </div>
        <div className="comment-main-container">
          <div className="comment-list">
            {comments.length > 0 ? (
              comments.map(comment => (
                <div key={comment.id} className="comment">
                  <span className="comment-author-info">
                    <span className="comment-author-first-name">{comment.user.name}</span>
                  </span>
                  <span className="comment-info">{comment.content}</span>
                </div>
              ))
            ) : (
              <p className="no-comments">Nu există comentarii.</p>
            )}
          </div>

          <div className="comment-bottom-button-container">
            <div className="add-a-comment-group">
              <input
                className="comment-input"
                type="text"
                placeholder="Add a comment..."
                value={newComment}
                maxLength={100}
                onChange={e => setNewComment(e.target.value)}
                autoFocus
              />
              <button className="send-button" onClick={handleSubmit}></button>
            </div>

            <svg height="10" width="100%" className="line">
              <line x1="0" y1="5" x2="100%" y2="5" style={{ stroke: 'black', strokeWidth: 1 }} />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostItem;
